import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Icon, IconNames } from '@/atoms/Icon';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import S3ClientNew from '@/utils/S3ClientNew';

import styles from './styles.module.css';

interface IDisplayInputsProps {
  displayInputs?: string[];
  type?: 'image' | 'keyValue';
  setIndex?: (index: number) => void;
  onRemove?: (label: string) => void;
  startIcon?: IconNames;
  onViewClick?: (imageUrl: string) => void;
}

export const DisplayInputs = (props: IDisplayInputsProps) => {
  const { displayInputs, type, onRemove, setIndex, startIcon, onViewClick } =
    props;

  const handleSetIndex = (index: number) => {
    setIndex && setIndex(index);
  };

  const handlePreviewClick = (imageUrl: string) => {
    onViewClick && onViewClick(imageUrl);
  };

  return (
    <div className={styles.labelContainer}>
      {displayInputs?.map((label, index: number) => (
        <div className={styles.labelWrap} key={index}>
          <ConditionalRendering showIf={startIcon}>
            <Icon
              name={startIcon as IconNames}
              propStyles={styles.filterClose}
            />
          </ConditionalRendering>
          <span className={styles.label} onClick={() => handleSetIndex(index)}>
            <Text
              text={type === 'image' ? S3ClientNew.fileName(label) : label}
              size='xs'
            />
          </span>
          <span className={styles.endIconWrapper}>
            <FlexDeprecated
              flexRow
              fullFlexWidth
              flexBasis={25}
              spacing={0}
              justifyContent='end'>
              <ConditionalRendering showIf={onViewClick}>
                <Icon
                  propStyles={styles.viewIcon}
                  onClick={() => handlePreviewClick(label)}
                  name='remove_red_eye_outlined'
                />
              </ConditionalRendering>
              <ConditionalRendering showIf={onRemove}>
                <Icon
                  propStyles={styles.filterClose}
                  onClick={() =>
                    onRemove &&
                    onRemove(type === 'keyValue' ? label.split(':')[0] : label)
                  }
                  name='close'
                />
              </ConditionalRendering>
            </FlexDeprecated>
          </span>
        </div>
      ))}
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  FixedBottomContainer,
  RowContainer,
} from '@/admin/components/common/Containers';
import { paymentModeOptions } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/components/PaymentForm';
import { Button } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import DropDownInput, {
  DropDownOptionType,
} from '@/booking/components/common/inputs/DropDownInput';
import FileUploadNew from '@/booking/components/common/inputs/FileUploadNew';
import TextInput from '@/booking/components/common/inputs/TextInput';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type { INote, IPaymentOrder } from '@/booking/interfaces';
import { useCreateOrUpdatePaymentOrderMutation } from '@/booking/services/paymentsAPISlice';
import useToast from '@/hooks/useToast';
import type { IProject } from '@/interfaces';
import { DateUtils } from '@/utils/DateUtils';
import { randomId } from '@/utils/utils';

import styles from './styles.module.css';

const schema = yup.object().shape({
  transactionId: yup.string().required(),
  amount: yup.number().required(),
  comment: yup.string(),
  settledAt: yup.date().required(),
  paymentSlips: yup.array().of(yup.string()).min(1),
});

interface IPaymentProcessCellProps {
  paymentOrder: IPaymentOrder;
  project: IProject;
  onPaymentUpdate: () => void;
}

const PaymentProcessCell = (props: IPaymentProcessCellProps) => {
  const { paymentOrder, project, onPaymentUpdate } = props;

  const [showComment, setShowComment] = useState(false);
  const [mode, setMode] = useState(paymentOrder.mode);
  const [receiptsUrl, setReceiptsUrl] = useState(paymentOrder.receiptsUrl);
  const [notes, setNotes] = useState(paymentOrder.notes || []);

  const [createOrUpdatePaymentOrderAPI, { isLoading }] =
    useCreateOrUpdatePaymentOrderMutation();
  const [addToast] = useToast();

  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    formState: { errors, submitCount },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('paymentSlips', receiptsUrl);
  }, [receiptsUrl]);

  const handleToggleComment = () => {
    setShowComment(!showComment);
  };

  const isReceiptUrlEmpty = !receiptsUrl?.length && submitCount > 0;

  const handleDropDownSelection = (value: DropDownOptionType<string>[]) => {
    setMode(value[0]?.value);
  };

  const handleAddPaymentSlips = (imageUrls: string[]) => {
    const urls = [
      ...(paymentOrder.receiptsUrl ? paymentOrder.receiptsUrl : []),
      ...imageUrls,
    ];
    setReceiptsUrl(urls);
  };

  const handleRemovePaymentSlips = (imageUrl: string) => {
    const urls = (
      paymentOrder.receiptsUrl ? paymentOrder.receiptsUrl : []
    ).filter((image) => image !== imageUrl);
    setReceiptsUrl(urls);
  };

  const handleNoteChange = (value: string) => {
    const updatedNotes: INote[] = [{ name: '', value: '' }];
    updatedNotes[0]['name'] = 'comment';
    updatedNotes[0]['value'] = value;

    setNotes(updatedNotes);
  };

  const handlePaymentProcess = async (paymentData: {
    transactionId: string;
    amount: number;
    settledAt: Date;
  }) => {
    try {
      await createOrUpdatePaymentOrderAPI({
        id: paymentOrder.id,
        status: PAYMENT_ORDER_STATUS.PROCESSING,
        transactionId: paymentData.transactionId,
        mode: mode,
        amount: paymentData.amount,
        notes: notes,
        settledAt: paymentData.settledAt,
        receiptsUrl: receiptsUrl,
      }).unwrap();

      onPaymentUpdate();
      // FIXME: @siddhant
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = error?.data?.message;

      addToast({
        type: 'ERROR',
        primaryMessage: errorMessage,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handlePaymentProcess)}>
        <div className={styles.contentWrapper}>
          <RowContainer>
            <DropDownInput
              options={paymentModeOptions}
              defaultSelectedOption={paymentModeOptions.find(
                ({ value }) => value === paymentOrder.mode
              )}
              lable='Payment Mode'
              onValueSelected={handleDropDownSelection}
            />
          </RowContainer>
          <RowContainer>
            <TextInput
              label='Date of Settlement'
              type='date'
              placeHolder='Enter Date'
              name='settledAt'
              defaultValue={
                paymentOrder.settledAt
                  ? DateUtils.format(
                      paymentOrder.settledAt,
                      DateUtils.FormatTo.YYYY_MM_DD
                    )
                  : undefined
              }
              register={register}
              setValue={setValue}
              unregister={unregister}
              errorMessage={
                errors.settledAt && 'Date of Settlement is required'
              }
            />
            <TextInput
              defaultValue={
                paymentOrder.amount ? paymentOrder.amount.toString() : ''
              }
              name='amount'
              label='Amount'
              type='number'
              placeHolder='Enter amount'
              startIconName='currency_rupee'
              register={register}
              setValue={setValue}
              unregister={unregister}
              errorMessage={errors.amount && 'Amount is required'}
            />
          </RowContainer>
          <RowContainer>
            <TextInput
              label='Transaction ID'
              type='text'
              defaultValue={paymentOrder.transactionId}
              placeHolder='Enter Transaction ID'
              name='transactionId'
              register={register}
              setValue={setValue}
              unregister={unregister}
              errorMessage={
                errors.transactionId && 'Transaction Id is required'
              }
            />
            <TextInput
              label='Date of Transaction'
              type='date'
              placeHolder='Enter Date'
              name='createdAt'
              defaultValue={
                paymentOrder.createdAt
                  ? DateUtils.format(
                      paymentOrder.createdAt,
                      DateUtils.FormatTo.YYYY_MM_DD
                    )
                  : undefined
              }
              readOnly
            />
          </RowContainer>

          <FileUploadNew
            placeholder='Upload Documents & Images'
            label='Upload Documents'
            s3DirPath={`projects/${project.id}/paymentSlips/${randomId()}`}
            name='paymentSlips'
            appType='RELATA'
            multiple
            showDisplayInputs
            displayInputs={receiptsUrl}
            onMultipleFileUpload={handleAddPaymentSlips}
            onRemove={handleRemovePaymentSlips}
            isFilePreviewEnabled
            errorMessage={isReceiptUrlEmpty && 'Please upload receipts'}
          />
          <ConditionalRendering showIf={showComment}>
            <TextInput
              name='comment'
              label='Comment'
              type='text'
              placeHolder='Add Comment'
              defaultValue={notes.length ? notes[0].value : ''}
              register={register}
              setValue={setValue}
              unregister={unregister}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNoteChange(e.target.value)
              }
            />
          </ConditionalRendering>
        </div>
        <FixedBottomContainer>
          <RowContainer>
            <Button accent='bordered' onClick={handleToggleComment}>
              Add Comment
            </Button>
            <Button accent='primary' type='submit' loading={isLoading}>
              Submit
            </Button>
          </RowContainer>
        </FixedBottomContainer>
      </form>
    </>
  );
};

export default PaymentProcessCell;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  AppliedFilters,
  FilterPreferences,
  computeSelectedPreferences,
  constructFilterPreferences,
} from '@/booking/components/unitSelection/utils';
import {
  getUpdatedUnitList,
  updateSectionBlockDropdownOptionsAvailableUnitsCnt,
  updateTowerDropdownOptionsAvailableUnitsCnt,
  updateUnitStatusByAppliedFilters,
} from '@/booking/slices/unitSelectionHeader/utils';
import { NOT_FOUND } from '@/constants/status';
import type { IUnit } from '@/interfaces';

export const UNIT_SELECTION_HEADER_NAV_STATE = {
  MASTER: 'MASTER',
  TOWER: 'TOWER',
};
export const DEFAULT_SELECTED_TOWER_NAME = 'towerName';
export const DEFAULT_SELECTED_SECTION = 'section';
export const DEFAULT_SELECTED_BLOCK = 'blockName';
export const DEFAULT_UNIT_CONFIG = '';

type IUnitSelectionHeaderNavState =
  (typeof UNIT_SELECTION_HEADER_NAV_STATE)[keyof typeof UNIT_SELECTION_HEADER_NAV_STATE];

export interface IOption {
  value: string;
  text: string;
  availablePreferredUnitCount: number;
  availableNonPreferredUnitCount: number;
}

export interface IUnitSelectionNav {
  key: string;
  fallBackText: string;
  defaultSelectedValue: string;
  selectedValue: string;
  options: IOption[];
}

export interface IUnitSelectionNavMenu {
  [key: string]: IUnitSelectionNav;
}

export interface IHierarchyUnitList {
  [towerName: string]: {
    [section: string]: {
      [config: string]: string[];
    };
  };
}

export interface IUnitById {
  [key: string]: IUnit;
}

export interface IState {
  unitSelectionHeaderNavState: IUnitSelectionHeaderNavState;
  unitListById: IUnitById;
  hierarchyUnitList: IHierarchyUnitList;
  selectedUnitConfig: string;
  unitSelectionNavMenu: IUnitSelectionNavMenu;
  selectedPreferences: AppliedFilters;
  filterPreferences: FilterPreferences;
}

const initialState: IState = {
  unitSelectionHeaderNavState: UNIT_SELECTION_HEADER_NAV_STATE.MASTER,
  unitListById: {},
  hierarchyUnitList: {},
  selectedUnitConfig: DEFAULT_UNIT_CONFIG,

  unitSelectionNavMenu: {
    towerName: {
      key: 'towerName',
      fallBackText: 'Tower',
      defaultSelectedValue: DEFAULT_SELECTED_TOWER_NAME,
      selectedValue: DEFAULT_SELECTED_TOWER_NAME,
      options: [
        {
          value: 'daisy',
          text: 'Daisy',
          availablePreferredUnitCount: 0,
          availableNonPreferredUnitCount: 0,
        },
      ],
    },
    section: {
      key: 'section',
      fallBackText: 'Floor',
      defaultSelectedValue: DEFAULT_SELECTED_SECTION,
      selectedValue: DEFAULT_SELECTED_SECTION,
      options: [
        {
          value: 'floor1',
          text: 'Floor 1',
          availablePreferredUnitCount: 0,
          availableNonPreferredUnitCount: 0,
        },
      ],
    },
    blockName: {
      key: 'blockName',
      fallBackText: 'Block',
      defaultSelectedValue: DEFAULT_SELECTED_BLOCK,
      selectedValue: DEFAULT_SELECTED_BLOCK,
      options: [
        {
          value: 'blockA',
          text: 'Block A',
          availablePreferredUnitCount: 0,
          availableNonPreferredUnitCount: 0,
        },
      ],
    },
  },
  selectedPreferences: {},
  filterPreferences: {},
};

const sliceName = 'unitSelectionHeaderNavSlice';

interface IUnitSelectionHeaderNavSlice {
  [sliceName]: IState;
}

export const unitSelectionHeaderNavSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // Unit
    setHierarchyUnitList: (
      state,
      action: PayloadAction<IHierarchyUnitList>
    ) => {
      state.hierarchyUnitList = action.payload;
    },
    setUnitListById: (state, action: PayloadAction<IUnitById>) => {
      state.unitListById = action.payload;

      updateTowerDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },
    setSelectedUnitConfig: (state, action: PayloadAction<string>) => {
      state.selectedUnitConfig = action.payload;
    },

    // Unit Selection Nav Menu
    setUnitSelectionHeaderNavState: (
      state,
      action: PayloadAction<IUnitSelectionHeaderNavState>
    ) => {
      state.unitSelectionHeaderNavState = action.payload;

      // Set default values
      if (
        state.unitSelectionHeaderNavState ===
        UNIT_SELECTION_HEADER_NAV_STATE.MASTER
      ) {
        for (const type in state.unitSelectionNavMenu) {
          const navMenuType = type as keyof typeof state.unitSelectionNavMenu;
          state.unitSelectionNavMenu[navMenuType].selectedValue =
            state.unitSelectionNavMenu[navMenuType].defaultSelectedValue;
        }
      }
    },

    updateUnitSelectionNavMenu: (state) => {
      updateTowerDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },

    // - Tower
    setSelectedTower: (state, action: PayloadAction<string>) => {
      const selectedTower = state.unitSelectionNavMenu.towerName.options.find(
        (option) => option.value === action.payload
      );
      if (
        selectedTower &&
        !(
          selectedTower.availablePreferredUnitCount +
          selectedTower.availableNonPreferredUnitCount
        )
      ) {
        return;
      }

      state.unitSelectionHeaderNavState = UNIT_SELECTION_HEADER_NAV_STATE.TOWER;

      state.unitSelectionNavMenu.towerName.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );

      const selectedSectionOption =
        state.unitSelectionNavMenu.section.options.find(
          (option) =>
            option.availablePreferredUnitCount +
            option.availableNonPreferredUnitCount
        );
      if (!selectedSectionOption) {
        return;
      }
      state.unitSelectionNavMenu.section.selectedValue =
        selectedSectionOption.value;

      state.filterPreferences = constructFilterPreferences(
        getUpdatedUnitList(state)
      );

      const selectedBlockOption =
        state.unitSelectionNavMenu.blockName.options.find(
          (option) =>
            option.availablePreferredUnitCount +
            option.availableNonPreferredUnitCount
        );
      if (!selectedBlockOption) {
        return;
      }
      state.unitSelectionNavMenu.blockName.selectedValue =
        selectedBlockOption.value;
    },

    // - Section
    setSelectedSection: (state, action: PayloadAction<string>) => {
      state.unitSelectionNavMenu.section.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );

      state.filterPreferences = constructFilterPreferences(
        getUpdatedUnitList(state)
      );
    },

    // - Block
    setSelectedBlock: (state, action: PayloadAction<string>) => {
      state.unitSelectionNavMenu.blockName.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );

      state.filterPreferences = constructFilterPreferences(
        getUpdatedUnitList(state)
      );
    },

    // Filter
    setFilterPreferences: (state, action: PayloadAction<FilterPreferences>) => {
      state.filterPreferences = action.payload;
    },
    toggleFilterPreferenceItem: (
      state,
      action: PayloadAction<{ key: string; itemIndex: number }>
    ) => {
      const { key, itemIndex } = action.payload;
      state.filterPreferences[key].items[itemIndex].isSelected =
        !state.filterPreferences[key].items[itemIndex].isSelected;

      const { isSelected } = state.filterPreferences[key].items[itemIndex];

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        isSelected,
        key,
        state.unitListById
      );
    },
    clearFilterPreferences: (state) => {
      Object.values(state.filterPreferences).forEach((preference) =>
        Object.values(preference.items).forEach(
          (item) => (item.isSelected = false)
        )
      );

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        false,
        null,
        state.unitListById
      );
    },
    resetFilterPreferenceKey: (state, action: PayloadAction<string>) => {
      const preferenceKey = action.payload;
      Object.values(state.filterPreferences[preferenceKey].items).forEach(
        (item) => (item.isSelected = false)
      );

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        false,
        preferenceKey,
        state.unitListById
      );
    },
  },
});

export const {
  // Unit
  setHierarchyUnitList,
  setUnitListById,
  setSelectedUnitConfig,

  // Unit Selection Nav Menu
  setUnitSelectionHeaderNavState,
  setSelectedTower,
  setSelectedSection,
  setSelectedBlock,
  updateUnitSelectionNavMenu,

  // Filter
  setFilterPreferences,
  toggleFilterPreferenceItem,
  clearFilterPreferences,
  resetFilterPreferenceKey,
} = unitSelectionHeaderNavSlice.actions;

// Unit
export const getHierarchyUnitList = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.hierarchyUnitList;
export const getUnitListById = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitListById;
export const getSelectedUnitConfig = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.selectedUnitConfig;

// Unit Selection Nav Menu
export const getUnitSelectionHeaderNavState = (
  state: IUnitSelectionHeaderNavSlice
) => state.unitSelectionHeaderNavSlice.unitSelectionHeaderNavState;
export const getUnitSelectionNavMenu = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu;

export const getTowerBtnState = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.towerName;
export const getSelectedTowerName = (state: IUnitSelectionHeaderNavSlice) => {
  return state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.towerName
    .selectedValue;
};
export const getTotalAvailableUnitCount = (
  state: IUnitSelectionHeaderNavSlice
) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.towerName.options.reduce(
    (count, option) =>
      count +
      option.availablePreferredUnitCount +
      option.availableNonPreferredUnitCount,
    0
  );
export const getSelectedTowerAvailableUnitCount = (
  state: IUnitSelectionHeaderNavSlice
) => {
  const selectedTowerName = getSelectedTowerName(state);
  const selectedOption =
    state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.towerName.options.find(
      (option) => option.value === selectedTowerName
    );

  return selectedOption
    ? selectedOption.availablePreferredUnitCount +
        selectedOption.availableNonPreferredUnitCount
    : NOT_FOUND;
};

export const getSectionBtnState = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.section;
export const getSelectedSection = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.section.selectedValue;
export const getSelectedSectionAvailableUnitCount = (
  state: IUnitSelectionHeaderNavSlice
) => {
  const selectedSection = getSelectedSection(state);
  const selectedOption =
    state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.section.options.find(
      (option) => option.value === selectedSection
    );

  return selectedOption
    ? selectedOption.availablePreferredUnitCount +
        selectedOption.availableNonPreferredUnitCount
    : NOT_FOUND;
};

export const getBlockBtnState = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.blockName;
export const getSelectedBlock = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.blockName
    .selectedValue;
export const getSelectedBlockAvailableUnitCount = (
  state: IUnitSelectionHeaderNavSlice
) => {
  const selectedBlock = getSelectedBlock(state);
  const selectedOption =
    state.unitSelectionHeaderNavSlice.unitSelectionNavMenu.blockName.options.find(
      (option) => option.value === selectedBlock
    );

  return selectedOption
    ? selectedOption.availablePreferredUnitCount +
        selectedOption.availableNonPreferredUnitCount
    : NOT_FOUND;
};
// Filter
export const getFilterPreferences = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.filterPreferences;
export const getSelectedPreferences = (state: IUnitSelectionHeaderNavSlice) =>
  state.unitSelectionHeaderNavSlice.selectedPreferences;

import type { IEOI } from '@/admin/interfaces';
import { adminAPISlice } from '@/admin/services';
import type { IResponseBase, TObjectId } from '@/interfaces';

export const eoiAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getEOIForUser: build.mutation({
      query: ({
        userId,
        projectId,
      }: {
        userId?: TObjectId;
        projectId?: TObjectId;
      }) => ({
        url: `api/projects/eoi/user/${userId}/project/${projectId}`,
      }),
      transformResponse: (response: { data: IEOI[]; error: object }) => {
        return { data: response.data };
      },
    }),
    getEoiByUserId: build.query<
      IEOI[],
      { userId: TObjectId; projectId: TObjectId }
    >({
      query: ({ userId, projectId }) => ({
        url: `api/projects/eoi/user/${userId}/project/${projectId}`,
      }),
      transformResponse: (response: ITransformEoiByUserIdResponse) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetEOIForUserMutation, useGetEoiByUserIdQuery } = eoiAPISlice;

interface ITransformEoiByUserIdResponse extends IResponseBase {
  data: IEOI[];
}

import { adminAPISlice } from '@/admin/services/index';
import type { IUser, TObjectId } from '@/interfaces';
import type {
  ITransformResponse,
  ITransformUserResponse,
  ITransformUsersResponse,
} from '@/interfaces/services';

export const adminUserAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    adminOtpSend: build.mutation({
      query: ({ email }: { email: string }) => ({
        url: 'api/otp/send',
        method: 'POST',
        body: { email },
      }),
    }),
    adminOtpVerify: build.mutation({
      query: ({ email, otp }: { email: string; otp: string }) => ({
        url: 'api/otp/verify',
        method: 'POST',
        body: { email, otp },
      }),
    }),
    offlinePaymentRequest: build.mutation({
      query: (unitCartId) => ({
        url: `api/otp/offlinePaymentRequest?unitCartId=${unitCartId}`,
        method: 'POST',
      }),
    }),
    otpv2Send: build.mutation({
      query: ({
        address,
        addressType,
        otpTemplate,
        bodyVars,
      }: {
        address: string;
        addressType: 'EMAIL' | 'MOBILE';
        otpTemplate: string;
        bodyVars?: {
          userId: string;
          projectName: string;
        };
      }) => ({
        url: 'api/otpv2/send',
        method: 'POST',
        body: { address, addressType, otpTemplate, bodyVars },
      }),
    }),
    otpv2Verify: build.mutation({
      query: ({ address, otp }: { address: string; otp: string }) => ({
        url: 'api/otpv2/verify',
        method: 'POST',
        body: { address, otp },
      }),
    }),

    getUserByEmail: build.mutation({
      query: ({ email }: { email: string }) => ({
        url: `api/users/${email}`,
        method: 'GET',
      }),
    }),
    getUserByMobile: build.mutation({
      query: ({ mobile }: { mobile: string }) => ({
        url: `api/users/mobile/${mobile}`,
        method: 'GET',
      }),
    }),
    getUserByMobileV2: build.query<IUser, string>({
      query: (mobile: string) => `api/users/mobile/${mobile}`,
      transformResponse: (response: ITransformUserResponse) => {
        return response.data;
      },
    }),
    getUserByUserId: build.query<IUser, string>({
      query: (userId: string) => `/api/users/userId/${userId}`,
      transformResponse: (response: ITransformResponse<IUser>) => {
        return response.data;
      },
    }),

    addAdminUser: build.mutation({
      query: ({
        name,
        email,
        password,
        role,
        mobile,
      }: {
        name: string;
        email: string;
        password: string;
        role: string;
        mobile: string;
      }) => ({
        url: 'api/users/',
        method: 'POST',
        body: { name, email, password, role, mobile },
      }),
    }),
    updateAdminUser: build.mutation({
      query: ({
        userId,
        user: { name, email, mobile, jobTitle },
      }: {
        userId: string;
        user: {
          name: string;
          email: string;
          mobile: string;
          jobTitle?: string;
        };
      }) => ({
        url: `api/users/${userId}`,
        method: 'PUT',
        body: { name, email, mobile, jobTitle },
      }),
    }),
    updateUserMetaData: build.mutation({
      query: (userMetaData: IUpdateUserMetaDataReq) => ({
        url: 'api/users/metaData/onSiteSuite',
        method: 'PUT',
        body: {
          occupation: userMetaData.data.occupation,
          ageGroup: userMetaData.data.ageGroup,
          ethnicity: userMetaData.data.ethnicity,
          source: userMetaData.data.source,
          location: userMetaData.data.location,
          city: userMetaData.data.city,
        },
        params: {
          userId: userMetaData.userId,
          projectId: userMetaData.projectId,
        },
      }),
    }),

    resetAdminUserPasswordByEmail: build.mutation({
      query: ({ email, password }: { email: string; password: string }) => ({
        url: 'api/users/reset-password',
        method: 'POST',
        body: { email, password },
      }),
    }),
    resetAdminUserPasswordByMobile: build.mutation({
      query: ({ mobile, password }: { mobile: string; password: string }) => ({
        url: 'api/users/reset-password',
        method: 'POST',
        body: { mobile, password },
      }),
    }),
    loginAdminUserByEmail: build.mutation({
      query: ({ email, password }: { email: string; password: string }) => ({
        url: 'api/auth/email',
        method: 'POST',
        body: { email, password },
      }),
    }),
    loginAdminUserByMobile: build.mutation({
      query: ({ mobile, password }: { mobile: string; password: string }) => ({
        url: 'api/auth/mobile',
        method: 'POST',
        body: { mobile, password },
      }),
    }),

    // this is booking-engine specific call, don't use it in any other place
    UNSAFE_loginAdminUserByMobileWithoutPassword: build.mutation({
      query: ({ mobile }: { mobile: string }) => ({
        url: 'api/auth/mobileWithoutPassword',
        method: 'POST',
        body: { mobile },
      }),
    }),

    getUsersByRole: build.query<IUser[], string>({
      query: (userRole: string) => `api/users/role/${userRole}`,
      transformResponse: (response: ITransformUsersResponse) => {
        return response.data;
      },
    }),

    isValidJwt: build.query({
      query: (jwtToken: string) => {
        return {
          url: `api/users/validate-jwt/${jwtToken}`,
          method: 'GET',
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
  useAdminOtpSendMutation,
  useAdminOtpVerifyMutation,
  useOfflinePaymentRequestMutation,

  useGetUserByEmailMutation,
  useGetUserByMobileMutation,
  useGetUserByMobileV2Query,
  useGetUserByUserIdQuery,
  useLazyGetUserByMobileV2Query,

  useAddAdminUserMutation,
  useUpdateAdminUserMutation,
  useUpdateUserMetaDataMutation,

  useResetAdminUserPasswordByEmailMutation,
  useResetAdminUserPasswordByMobileMutation,

  useLoginAdminUserByEmailMutation,
  useLoginAdminUserByMobileMutation,
  useUNSAFE_loginAdminUserByMobileWithoutPasswordMutation,

  useGetUsersByRoleQuery,

  useIsValidJwtQuery,
} = adminUserAPISlice;

export interface IUpdateUserMetaDataReq {
  userId: TObjectId;
  projectId?: TObjectId;
  data: {
    occupation?: string;
    ageGroup?: string;
    ethnicity?: string;
    source?: { name?: string; subSource?: string };
    location?: string;
    city?: string;
  };
}
